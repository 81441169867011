import "@sakun/system.css";
import React from "react";

const Header = () => {
  return (
    <header className="menu-bar" style={{ fontSize: "1.8rem" }}>
      <ul role="menu-bar">
        <li role="menu-item" tabIndex="0" aria-haspopup="false">
          <a href="/">Home</a>
        </li>
        <li role="menu-item" tabIndex="0" aria-haspopup="false">
          <a href="/about">About</a>
        </li>
      </ul>
    </header>
  );
};

export default Header;
