import React from "react";
import { projectsData } from "../projectsData";
import Project from "./project";

const Home = () => {
  return (
    <main>
      <section className="mb-4">
        <h2 className="h4">My Projects</h2>
        {projectsData.map((project, index) => (
          <Project
            key={index}
            title={project.title}
            description={project.description}
            imageUrls={project.imageUrls}
            link={project.link}
            date={project.date}
          />
        ))}
      </section>
    </main>
  );
};

export default Home;
