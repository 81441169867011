import React, { useEffect, useState } from "react";

const Project = ({ title, description, imageUrls, date, link }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (showModal) {
        if (event.key === "ArrowLeft") {
          setCurrentImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : imageUrls.length - 1
          );
        } else if (event.key === "ArrowRight") {
          setCurrentImageIndex((prevIndex) =>
            prevIndex < imageUrls.length - 1 ? prevIndex + 1 : 0
          );
        } else if (event.key === "Escape") {
          setShowModal(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [showModal, imageUrls]);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div
      className="window"
      style={{
        backgroundColor: isHovered ? "#f0f0f0" : "transparent",
        transition: "background-color 0.3s ease",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="title-bar">
        <h1 className="title">
          {title} ({date})
        </h1>
      </div>
      <div className="window-pane">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div>{description}</div>
          <div
            style={{
              paddingTop: "1rem",
            }}
          >
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="btn"
              style={{ marginRight: "0.5rem" }}
            >
              {link.title}
            </a>
          </div>
        </div>
        <div
          style={{
            paddingTop: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              overflowX: "auto",
              gap: "1rem",
              paddingBottom: "1rem",
            }}
          >
            {imageUrls &&
              imageUrls.map((imageUrl, index) => (
                <img
                  key={index}
                  src={imageUrl}
                  alt={`${title} ${index + 1}`}
                  style={{
                    height: window.innerWidth > 768 ? "400px" : "300px",
                    objectFit: "cover",
                    cursor: "pointer",
                    flexShrink: 0,
                  }}
                  onClick={() => openModal(index)}
                  onError={(e) => {
                    console.error(`Failed to load image: ${imageUrl}`);
                    e.target.style.display = "none";
                  }}
                />
              ))}
          </div>
        </div>
      </div>
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
          onClick={closeModal}
        >
          <img
            src={imageUrls[currentImageIndex]}
            alt={`${title} ${currentImageIndex + 1}`}
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Project;
