export const projectsData = [
  {
    title: "JuicyPomo",
    description: "Boost productivity with a minimalist timer.",
    imageUrls: [
      "/images/pomo/pomo-0.webp",
      "/images/pomo/pomo-1.webp",
      "/images/pomo/pomo-2.webp",
      "/images/pomo/pomo-3.webp",
    ],
    date: "2024",
    link: {
      url: "https://apps.apple.com/us/app/juicypomo/id6504472667?platform=iphone",
      title: " AppStore",
    },
  },
  {
    title: "iBooks Anki",
    description:
      "A tool to automate the process of creating Anki flashcard from Apple Books highlighted words and got them translated.",
    imageUrls: ["/images/ibooks-anki.jpg"],
    date: "2020",
    link: {
      url: "https://github.com/xieweizhi/ibooks_anki",
      title: "GitHub",
    },
  },
  {
    title: "Tubi TV Flutter",
    description:
      "A Flutter implementation of Tubi TV, featuring a clean UI, video playback, and integration with the Tubi TV API. This project demonstrates mobile app development skills using Flutter framework.",
    imageUrls: [
      "/images/tubi-tv/0.jpg",
      "/images/tubi-tv/1.jpg",
      "/images/tubi-tv/2.jpg",
    ],
    date: "2019",
    link: {
      url: "https://github.com/xieweizhi/tubi_tv_flutter",
      title: "GitHub",
    },
  },
  {
    title: "Flutter Classic Kit",
    description:
      "A collection of classic UI components for Flutter, inspired by Windows 95, 98, and XP. This project showcases the ability to recreate retro designs using modern Flutter framework, demonstrating both UI development skills and a creative approach to design.",
    imageUrls: [
      "/images/classic-kit/image-0.png",
      "/images/classic-kit/image-1.png",
    ],
    date: "2019",
    link: {
      url: "https://github.com/xieweizhi/flutter_classic_kit",
      title: "GitHub",
    },
  },
];
