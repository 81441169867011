import React from "react";
import "./about.css"; // We'll create this file for custom styles

const About = () => {
  return (
    <div className="window">
      <div className="title-bar">
        <h1 className="title">About Me</h1>
      </div>
      <div className="window-pane">
        <div className="about-content">
          <img src="images/avatar.jpeg" alt="Weizhi" className="avatar" />
          <div className="info">
            <h2>Weizhi</h2>
            <p>Chinese developer living in Tokyo, Japan</p>
            <h3 style={{ textAlign: "left" }}>My Expertise</h3>
            <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
              <li>iOS Development since 2014</li>
              <li>React Native</li>
              <li>Web Development</li>
            </ul>
            <h3>Contact</h3>
            <p>
              Email:{" "}
              <a href="mailto:deexie1990@gmail.com">deexie1990@gmail.com</a>
            </p>
            <p>
              GitHub:{" "}
              <a
                href="https://github.com/xieweizhi"
                target="_blank"
                rel="noopener noreferrer"
              >
                github.com/xieweizhi
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
